import { createRouter, createWebHistory } from "vue-router";
// import store from "@/store";
import PopUp from "../views/TestPopup.vue";
import ChartComp from "../views/TreeChart.vue";
import { isAdmin, isEmployee, isHR, isTrustee } from "./auth";

import { loginGuards, employeeGuards, hrGuards, adminGuards, trusteeGuards } from './guards';

const routes = [
  {
    path: "/help",
    name: "HelpView",
    meta: { requiresAuth: true, },
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/HelpView.vue"
      ),
  },
  {
    path: "/employee/dashboard",
    name: "EmpDashboard",
    meta: { requiresAuth: true, requiredRole: "employee" },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Dashboard.vue"
      ),
  },
  {
    path: "/employee/profile",
    name: "EmpProfile",
    beforeEnter: employeeGuards,
    meta: { requiresAuth: true, requiredRole: "employee"},
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Profile.vue"
      ),
  },
  {
    path: "/employee/attendance/today",
    name: "EmpAttendanceToday",
    meta: { requiresAuth: true, requiredRole: "employee" },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Attendance/TodayList.vue"
      ),
  },
  {
    path: "/employee/team",
    name: "EmpTeam",
    meta: { requiresAuth: true, requiredRole: "employee" },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Team/MyTeam.vue"
      ),
  },
  {
    path: "/employee/task",
    name: "EmpTask",
    meta: { requiresAuth: true, requiredRole: "employee" },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Task/MyTask.vue"
      ),
  },
  {
    path: "/employee/compensation",
    name: "EmpComp",
    meta: { requiresAuth: true, requiredRole: "employee" },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Compensation/MyCompensation.vue"
      ),
  },
  {
    path: "/employee/notification",
    name: "EmpNotification",
    meta: { requiresAuth: true, requiredRole: "employee" },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Notification/EmpNotification.vue"
      ),
  },
  {
    path: "/employee/leave/holiday",
    name: "EmpHolidayList",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Leave/HolidayList.vue"
      ),
  },
  {
    path: "/employee/leave/type",
    name: "EmpLeaveType",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Leave/LeaveType.vue"
      ),
  },
  {
    path: "/employee/attendance/all",
    name: "EmpAttendanceAll",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Attendance/AttendanceList.vue"
      ),
  },
  {
    path: "/employee/leave/list",
    name: "EmpLeaveList",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Leave/LeaveList.vue"
      ),
  },
  {
    path: "/employee/permission/list",
    name: "EmpPermissionList",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Leave/PermissionList.vue"
      ),
  },
  {
    path: "/employee/leave/regularization",
    name: "EmpRegularizationList",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Leave/RegularizationList.vue"
      ),
  },
  {
    path: "/employee/permission/request",
    name: "EmpPermissionRequest",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Leave/PermissionRequest.vue"
      ),
  },
  {
    path: "/employee/leave/request",
    name: "EmpLeaveRequest",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Leave/LeaveRequest.vue"
      ),
  },
  {
    path: "/employee/ticket/list",
    name: "EmpTicketList",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Tickets/TicketList.vue"
      ),
  },
  {
    path: "/employee/ticket/create",
    name: "EmpTicketCreate",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Tickets/TicketCreate.vue"
      ),
  },
  {
    path: "/employee/payroll",
    name: "EmpPaySlipList",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Payslip.vue"
      ),
  },
  {
    path: "/employee/mycalendar",
    name: "EmpMyCalendar",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Calendar/MyCalendar.vue"
      ),
  },
  {
    path: "/employee/profile-tickets",
    name: "ProfileTickets",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/ProfileTickets.vue"
      ),
  },
  {
    path: "/employee/platform-support",
    name: "PlatformSupport",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Platform/PlatformSupport.vue"
      ),
  },
  {
    path: "/employee/platform-support-list",
    name: "PlatformList",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Platform/PlatformList.vue"
      ),
  },
  {
    path: "/employee/download-manual",
    name: "DownloadManual",
    meta: { requiresAuth: true },
    beforeEnter: employeeGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/EmpViews/Platform/DownloadManual.vue"
      ),
  },
  {
    path: "/admin/dashboard",
    meta: { requiresAuth: true, requiredRole: "admin" },
    name: "AdDashboard",
    beforeEnter: adminGuards,
    component: () =>
    import(
      "../views/AdViews/Dashboard.vue"
    ),
  },
  {
    path: "/admin/notification",
    meta: { requiresAuth: true, requiredRole: "admin" },
    name: "AdNotification",
    beforeEnter: adminGuards,
    component: () =>
    import(
      "../views/AdViews/Notification/NotificationView.vue"
    ),
  },
  {
    path: "/admin/team",
    name: "AdTeam",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Team/MyTeam.vue"
      ),
  },
  {
    path: "/admin/download-list",
    name: "AdDownload",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Download/DownloadList.vue"
      ),
  },
  {
    path: "/admin/announcement",
    name: "AdAnnouncement",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Announcements/AnnouncementView.vue"
      ),
  },
  {
    path: "/admin/announcement-list",
    name: "AdAnnouncementList",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Announcements/AnnouncementList.vue"
      ),
  },
  {
    path: "/admin/event",
    name: "AdEvent",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Announcements/CreateEvent.vue"
      ),
  },
  {
    path: "/admin/event-list",
    name: "AdEventList",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Announcements/EventList.vue"
      ),
  },
  {
    path: "/admin/attendance/today/",
    name: "AdAttendanceToday",
    meta: { requiresAuth: true, requiredRole: "admin" },
    props: route => ({ status: route.query.status }),
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Attendance/TodayEmployeeAttendanceList.vue"
      ),
  },
  {
    path: "/admin/attendance/today/employee",
    name: "TodayEmployeeAttendanceList",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Attendance/TodayList.vue"
      ),
  },
  {
    path: "/admin/attendace/all",
    name: "AdAttendanceAll",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Attendance/AttendanceList.vue"
      ),
  },
  {
    path: "/admin/leave/request",
    name: "AdLeaveRequest",
    meta: { requiresAuth: true },
    beforeEnter: adminGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/AdViews/Leave/LeaveRequest.vue"
      ),
  },
  {
    path: "/admin/leave/all",
    name: "AdLeaveAll",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Leave/LeaveList.vue"
      ),
  },
  {
    path: "/admin/requests/permission",
    name: "AdPermissionRequests",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Leave/PermissionRequests.vue"
      ),
  },
  {
    path: "/admin/leave/regularization",
    name: "AdRegularization",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Leave/RegualrizationList.vue"
      ),
  },
  {
    path: "/admin/ticket/profile",
    name: "AdProfileTicket",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Tickets/ProfileRequest.vue"
      ),
  },
  {
    path: "/admin/ticket/picture",
    name: "AdPictureTicket",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Tickets/PictureRequest.vue"
      ),
  },
  {
    path: "/admin/ticket/education",
    name: "AdEducationTicket",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Tickets/EducationRequest.vue"
      ),
  },
  {
    path: "/admin/ticket/work",
    name: "AdWorkTicket",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Tickets/WorkRequest.vue"
      ),
  },
  {
    path: "/admin/ticket/contact",
    name: "AdContactTicket",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Tickets/ContactRequest.vue"
      ),
  },
  {
    path: "/admin/ticket/finance",
    name: "AdFinanceTicket",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Tickets/FinanceRequest.vue"
      ),
  },
  {
    path: "/admin/ticket/emergency",
    name: "AdEmergencyTicket",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Tickets/EmergencyRequest.vue"
      ),
  },
  {
    path: "/admin/ticket/list",
    name: "AdTicketList",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Tickets/TicketList.vue"
      ),
  },
  {
    path: "/admin/employee/list",
    name: "AdEmployeeList",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Employee/EmployeeList.vue"
      ),
  },
  {
    path: "/admin/employee/profile/:id",
    name: "AdEmployeeProfile",
    props: true,
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Employee/EmployeeProfile.vue"
      ),
  },
  {
    path: "/admin/platform-support",
    name: "AdPlatformSupport",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/AdViews/Platform/PlatformSupport.vue"
      ),
  },
  {
    path: "/admin/mycalendar",
    name: "AdMyCalendar",
    meta: { requiresAuth: true },
    beforeEnter: adminGuards,
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/AdViews/Calendar/MyCalendar.vue"
      ),
  },
  {
    path: "/admin/platform-support-list",
    name: "AdPlatformList",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/AdViews/Platform/PlatformList.vue"
      ),
  },
  {
    path: "/admin/profile",
    name: "AdProfile",
    meta: { requiresAuth: true, requiredRole: "admin" },
    beforeEnter: adminGuards,
    component: () =>
      import(
        "../views/AdViews/Profile.vue"
      ),
  },
  {
    path: "/trustee/dashboard",
    name: "TrusteeDashboard",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Dashboard.vue"
      ),
  },
  {
    path: "/trustee/announcement",
    name: "TrusteeAnnouncement",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Announcements/AnnouncementView.vue"
      ),
  },
  {
    path: "/trustee/announcement-list",
    name: "TrusteeAnnouncementList",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Announcements/AnnouncementList.vue"
      ),
  },
  {
    path: "/trustee/notification-list",
    name: "TrusteeNotification",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Notification/NotificationPage.vue"
      ),
  },
  {
    path: "/trustee/event",
    name: "TrusteeEvents",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Announcements/CreateEvent.vue"
      ),
  },
  {
    path: "/trustee/download-list",
    name: "TrusteeDownloadList",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Download/DownloadList.vue"
      ),
  },
  {
    path: "/trustee/mycalendar",
    name: "TrusteeMyCalendar",
    meta: { requiresAuth: true },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        /* webpackChunkName: "trustee" */ "../views/TrusteeViews/Calendar/MyCalendar.vue"
      ),
  },
  {
    path: "/trustee/event-list",
    name: "TrusteeEventsList",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Announcements/EventList.vue"
      ),
  },
  {
    path: "/trustee/attendance/today",
    name: "TrusteeAttendanceToday",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    props: route => ({ status: route.query.status }),
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Attendance/TodayList.vue"
      ),
  },
  {
    path: "/trustee/attendace/all",
    name: "TrusteeAttendanceAll",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Attendance/AttendanceList.vue"
      ),
  },
  {
    path: "/trustee/permission/all",
    name: "TrusteePermissionAll",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Leave/PermissionRequest.vue"
      ),
  },
  {
    path: "/trustee/leave/all",
    name: "TrusteeLeaveAll",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Leave/LeaveList.vue"
      ),
  },
  {
    path: "/trustee/leave/holiday",
    name: "TrusteeHoliday",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Leave/HolidayList.vue"
      ),
  },
  {
    path: "/trustee/leave/holiday-create",
    name: "TrusteeHolidayCreate",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Leave/CreateHoliday.vue"
      ),
  },
  {
    path: "/trustee/leave/hr-principal-leave",
    name: "TrusteeHRLeave",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Leave/HRLeave.vue"
      ),
  },
  {
    path: "/trustee/regularization",
    name: "TrusteeRegularization",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Leave/RegularizationList.vue"
      ),
  },
  {
    path: "/trustee/ticket/profile",
    name: "TrusteeProfileTicket",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Tickets/ProfileRequest.vue"
      ),
  },
  {
    path: "/trustee/ticket/picture",
    name: "TrusteePictureTicket",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Tickets/PictureRequest.vue"
      ),
  },
  {
    path: "/trustee/ticket/education",
    name: "TrusteeEducationTicket",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Tickets/EducationRequest.vue"
      ),
  },
  {
    path: "/trustee/ticket/work",
    name: "TrusteeWorkTicket",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Tickets/WorkRequest.vue"
      ),
  },
  {
    path: "/trustee/ticket/contact",
    name: "TrusteeContactTicket",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Tickets/ContactRequest.vue"
      ),
  },
  {
    path: "/trustee/ticket/finance",
    name: "TrusteeFinanceTicket",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Tickets/FinanceRequest.vue"
      ),
  },
  {
    path: "/trustee/ticket/emergency",
    name: "TrusteeEmergencyTicket",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Tickets/EmergencyRequest.vue"
      ),
  },
  {
    path: "/trustee/ticket/list",
    name: "TrusteeTicketList",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Tickets/TicketList.vue"
      ),
  },
  {
    path: "/trustee/campus-requests",
    name: "TrusteeCampusRequests",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Leave/CampusRequests.vue"
      ),
  },
  {
    path: "/trustee/employee/list",
    name: "TrusteeEmployeeList",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Employee/EmployeeList.vue"
      ),
  },
  {
    path: "/trustee/employee/profile/:id",
    name: "TrusteeEmployeeProfile",
    props: true,
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Employee/EmployeeProfile.vue"
      ),
  },
  {
    path: "/trustee/profile",
    name: "TrusteeProfile",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Profile.vue"
      ),
  },
  {
    path: "/trustee/platform-support-list",
    name: "TrusteePlatform",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Platform/PlatformList.vue"
      ),
  },
  {
    path: "/trustee/platform-support",
    name: "TrusteePlatformRequest",
    meta: { requiresAuth: true, requiredRole: "trustee" },
    beforeEnter: trusteeGuards,
    component: () =>
      import(
        "../views/TrusteeViews/Platform/PlatformSupport.vue"
      ),
  },
  {
    path: "/hr/dashboard",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    name: "HrDashboard",
    component: () =>
    import(
      "../views/HrViews/Dashboard.vue"
    ),
  },
  {
    path: "/hr/download-list",
    name: "HrDownload",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Download/DownloadList.vue"
      ),
  },
  {
    path: "/hr/announcement",
    name: "HrAnnouncement",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Announcements/AnnouncementView.vue"
      ),
  },
  {
    path: "/hr/announcement-list",
    name: "HrAnnouncementList",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Announcements/AnnouncementList.vue"
      ),
  },
  {
    path: "/hr/event",
    name: "HrEvent",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Announcements/CreateEvent.vue"
      ),
  },
  {
    path: "/hr/event-list",
    name: "HrEventList",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Announcements/EventList.vue"
      ),
  },
  {
    path: "/hr/attendance/add",
    name: "HrAttendanceAdd",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/HrViews/Attendance/HrAttendanceAdd.vue"
      ),
  },
  {
    path: "/hr/attendance/today/employee/",
    name: "TodayEmployeeAttendanceList",
    meta: { requiresAuth: true, requiredRole: "hr" },
    props: route => ({ status: route.query.status }),
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Attendance/TodayEmployeeAttendanceList.vue"
      ),
  },
  {
    path: "/hr/attendace/all",
    name: "HrAttendanceAll",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Attendance/AttendanceList.vue"
      ),
  },
  {
    path: "/hr/leave/request",
    name: "HrLeaveRequest",
    meta: { requiresAuth: true },
    beforeEnter: hrGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/HrViews/Leave/LeaveRequest.vue"
      ),
  },
  {
    path: "/hr/holiday/create",
    name: "HrHolidayCreate",
    meta: { requiresAuth: true },
    beforeEnter: hrGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/HrViews/Leave/CreateHoliday.vue"
      ),
  },
  {
    path: "/hr/holiday/List",
    name: "HrHolidayList",
    meta: { requiresAuth: true },
    beforeEnter: hrGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/HrViews/Leave/HolidayList.vue"
      ),
  },
  {
    path: "/hr/permission/all",
    name: "HrPermissionAll",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Leave/PermissionRequest.vue"
      ),
  },
  {
    path: "/hr/regularization/all",
    name: "HrRegularizationAll",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Leave/RegularizationRequest.vue"
      ),
  },
  {
    path: "/hr/leave/all",
    name: "HrLeaveAll",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Leave/LeaveList.vue"
      ),
  },
  {
    path: "/hr/notification",
    name: "HrNotification",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/HrViews/Notification/HrNotification.vue"
      ),
  },
  {
    path: "/hr/ticket/profile",
    name: "HrProfileTicket",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Tickets/ProfileRequest.vue"
      ),
  },
  {
    path: "/hr/ticket/picture",
    name: "HrPictureTicket",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Tickets/PictureRequest.vue"
      ),
  },
  {
    path: "/hr/ticket/education",
    name: "HrEducationTicket",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Tickets/EducationRequest.vue"
      ),
  },
  {
    path: "/hr/ticket/work",
    name: "HrWorkTicket",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Tickets/WorkRequest.vue"
      ),
  },
  {
    path: "/hr/ticket/contact",
    name: "HrContactTicket",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Tickets/ContactRequest.vue"
      ),
  },
  {
    path: "/hr/ticket/finance",
    name: "HrFinanceTicket",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Tickets/FinanceRequest.vue"
      ),
  },
  {
    path: "/hr/ticket/emergency",
    name: "HrEmergencyTicket",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Tickets/EmergencyRequest.vue"
      ),
  },
  {
    path: "/hr/ticket/list",
    name: "HrTicketList",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Tickets/TicketList.vue"
      ),
  },
  {
    path: "/hr/platform",
    name: "HrPlatform",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Platform/PlatformList.vue"
      ),
  },
  {
    path: "/hr/platform-support",
    name: "HrPlatformSupport",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Platform/PlatformSupport.vue"
      ),
  },
  {
    path: "/hr/employee/list",
    name: "HrEmployeeList",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Employee/EmployeeList.vue"
      ),
  },
  {
    path: "/hr/employee/archive",
    name: "HrArchiveList",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Employee/ArchiveList.vue"
      ),
  },
  {
    path: "/hr/employee/create",
    name: "HrEmployeeCreate",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Employee/CreateEmployee.vue"
      ),
  },
  {
    path: "/hr/employee/edit/:id/:user",
    name: "HrEmployeeEdit",
    meta: { requiresAuth: true, requiredRole: "hr" },
    props:true,
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Employee/EmployeeEdit.vue"
      ),
  },
  {
    path: "/hr/employee/view/:id/",
    name: "HrEmployeeProfile",
    meta: { requiresAuth: true, requiredRole: "hr" },
    props:true,
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Employee/EmployeeProfile.vue"
      ),
  },
  {
    path: "/hr/profile",
    name: "HrProfile",
    meta: { requiresAuth: true, requiredRole: "hr" },
    beforeEnter: hrGuards,
    component: () =>
      import(
        "../views/HrViews/Profile.vue"
      ),
  },
  {
    path: "/hr/mycalendar",
    name: "HrMyCalendar",
    meta: { requiresAuth: true },
    beforeEnter: hrGuards,
    component: () =>
      import(
        /* webpackChunkName: "employee_dashboard" */ "../views/HrViews/Calendar/MyCalendar.vue"
      ),
  },
  {
    path: "/",
    name: "LoginView",
    meta: { requiresAuth: false },
    beforeEnter: loginGuards,
    component: () =>
      import(
        "../views/LoginView.vue"
      ),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: { requiresAuth: false },
    component: () =>
      import(
        "../views/ForgetPassword.vue"
      ),
  },
  {
    path: "/reset-password/:userId/:token",
    name: "ResetPassword",
    meta: { requiresAuth: false },
    props: true,
    component: () =>
      import(
        "../views/ResetPassword.vue"
      ),
  },
  {
    path: "/testpopup",
    name: "TestPopup",
    component: PopUp,
  },
  {
    path: "/treechart",
    name: "TreeChart",
    component: ChartComp,
  },
  {
    path: "/webcam/",
    name: "WebCam",
    component: () =>
      import(/* webpackChunkName: "webcam" */ "../components/WebCam.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(/* webpackChunkName: "webcam" */ "../components/PrivacyPolicy.vue"),
  },
  {
    path: "/:catchAll(.*)",
    component: () =>
      import(/* webpackChunkName: "webcam" */ "../components/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (!store.getters.isAuthenticated) {
//       next("/");
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

router.beforeEach((to, from, next) => {

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiredRole = to.matched.some((record) => record.meta.requiredRole);

  if (requiresAuth) {
    if (requiredRole === "trustee" && !isTrustee()) {
      next("/");
    }
    if (requiredRole === "admin" && !isAdmin()) {
      next("/");
    }
    if (requiredRole === "employee" && !isEmployee()) {
      next("/");
    } 
    if (requiredRole === "hr" && !isHR()) {
      next("/");
    } 
    else {
      next();
    }
  } else {
    next();
  }
});



export default router;
